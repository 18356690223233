<template>
  <v-container id="user-table" fluid tag="section">
    <set-form
      v-bind:qid="qid"
      ref="editForm"
      :data="meta"
      @itemSaved="onSaved"
    ></set-form>
    <base-panel icon="mdi-calendar-question" :title="$t('sets.page title')">
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('sets.delete set')"
        :confirmation="$t('sets.deleted')"
        @itemDeleted="onDeleted"
      >
        <template v-slot:default="item">{{
          $t("sets.delete confirm", { name: item.name })
        }}</template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>
      </delete-dialog>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [20, 50, 100],
        }"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer />
            <v-btn color="primary" dark class="mb-2" @click="onAdd">
              <v-icon left>mdi-plus</v-icon>
              {{ $t("quiz.add") }}
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:[`item.gender`]="{ item }">
          {{ $t("norm.gender_" + item.gender) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onEdit(item)">mdi-pencil</v-icon>
          <v-icon small @click="onDelete(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";

import SetForm from "@/pages/sets/SetForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
export default {
  components: {
    DeleteDialog,
    SetForm,
  },
  props: ["qid", "refresh"],
  mixins: [crudMixin],

  data() {
    return {
      apiQuery: "/quiz-sets/",
      headers: [
        { text: this.$t("sets.name"), value: "name" },
        { text: this.$t("sets.quiz_count"), value: "quiz_count" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },

  created() {
    this.loadMeta();
  },
  methods: {},
};
</script>
