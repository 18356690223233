var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-table","fluid":"","tag":"section"}},[_c('set-form',{ref:"editForm",attrs:{"qid":_vm.qid,"data":_vm.meta},on:{"itemSaved":_vm.onSaved}}),_c('base-panel',{attrs:{"icon":"mdi-calendar-question","title":_vm.$t('sets.page title')}},[_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('sets.delete set'),"confirmation":_vm.$t('sets.deleted')},on:{"itemDeleted":_vm.onDeleted},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_vm._v(_vm._s(_vm.$t("sets.delete confirm", { name: item.name })))]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [20, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("quiz.add"))+" ")],1)],1)]},proxy:true},{key:"item.gender",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("norm.gender_" + item.gender))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }