<template>
  <v-dialog v-model="showFormDialog" @click:outside="closeSelect" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  :label="$t('sets.name')"
                  :rules="rules.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.notices"
                  :label="$t('sets.notices')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="form.quizzes"
                  :items="data.quizzes"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('sets.quizzes')"
                  multiple
                  @focus="onSelect = true"
                  @blur="onSelectBlur"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email_subject"
                  :label="$t('sets.email subject')"
                  :rules="rules.subject"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  clearable
                  clear-icon="mdi-close-circle"
                  :label="$t('sets.email message')"
                  v-model="form.email_message"
                  :rules="rules.messsage"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("norm.category saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  mounted() {},
  data() {
    return {
      apiQuery: "/quiz-sets/",
      defaultItem: {},
      onSelect: false,
      reloadBeforeEdit: true,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("sets.name") }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("sets.name"),
            }),
        ],
        subject: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("sets.email subject") }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("sets.email subject"),
            }),
        ],
        message: [
          (v) =>
            !!v ||
            this.$t("validation.required", { name: this.$t("sets.email message") }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("sets.email message"),
            }),
        ],
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("sets.add")
        : this.$t("sets.edit");
    },
  },

  methods: {
    onSelectBlur() {
      setTimeout( () => {
        this.onSelect = false;
      }, 500);
    },
    closeSelect() {
      if (!this.onSelect) {
        this.closeForm();
      }
    },
  },
};
</script>
